<template>
    <div>
        <b-form-group v-if="!value || value.length === 0">
            <b-overlay :show="fileUploading">
                <b-form-file
                    v-model="imageFile"
                    :disabled="fileUploading"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                />
            </b-overlay>
            <b-progress v-if="fileUploading" class="mt-1" :value="progress" :max="100" show-progress></b-progress>
        </b-form-group>
        <div v-else>
            <b-form-group>
                <img :src="'/api/management/v1/image/' + value" style="max-width: 100%; max-height: 400px" class="rounded-lg" alt="Image"/>
            </b-form-group>

            <b-form-group >
                <b-button variant="danger" @click="$emit('input', '')">
                    <feather-icon
                        icon="Trash2Icon"
                        class="mr-50"
                    />
                    <span class="align-middle">Remove image</span>
                </b-button>

            </b-form-group>
        </div>
    </div>
</template>
<script>

    import {BButton, BFormFile, BFormGroup, BProgress, BOverlay} from 'bootstrap-vue'

    export default {
        props: {
            value: {
                type: String,
                required: true,
                default: ''
            }
        },
        components: {
            BFormFile,
            BFormGroup,
            BButton,
            BProgress,
            BOverlay
        },
        data() {
            return {
                imageFile: null,

                fileUploading: false,
                progress: 0
            }
        },
        methods: {
            valid() {
                if (this.value === '') return false
                return true
            }
        },
        watch: {
            imageFile() {
                if (!this.imageFile) return
                const thisIns = this
                const formData = new FormData()
                this.fileUploading = true
                formData.append('file', this.imageFile)

                this.progress = 0

                this.$http.post('/api/management/v1/image/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress(progressEvent) {
                        thisIns.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    }
                }).then(function(response) {
                    thisIns.$printSuccess('Image uploaded')
                    thisIns.$emit('input', response.data)
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.fileUploading = false
                })
            }
        }
    }

</script>